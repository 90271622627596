<template>
  <v-menu
    :close-on-content-click="true"
    :nudge-width="50"
    :nudge-bottom="10"
    offset-y
    z-index="100"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        class="ml-2 pr-1"
        :class="{'fill-width d-flex justify-center': $vuetify.breakpoint.xsOnly, }"
        style="overflow: visible !important"
        label
        :outlined="selected_sort.length === 0"
        :color="selected_sort.length > 0 ? 'primary' : ''"
        v-bind="attrs"
        v-on="on"
      >
      <span class="font-weight-bold">
        <v-icon
          size="22"
          class="mr-1 pl-0"
          v-text="'mdi-filter-outline'"
        />
        Filtrar
        <v-icon
          size="22"
          class="ml-1 pr-0"
          v-text="'mdi-menu-down'"
        />
      </span>
      </v-chip>
    </template>
    <v-card>
      <v-card-text class="px-0">
        <v-row>
          <v-col cols="12" class="py-0">
            <v-list class="py-0">
              <v-list-item-group v-model="selected_sort" multiple active-class="icono--text">
                <v-list-item
                  v-for="(item, i) in options"
                  :key="'optionFilter_'+i"
                  :value="item.value"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-icon class="mr-1">
                      <v-icon v-if="!active" size="20" color="icono" v-text="'mdi-calendar-month-outline'" />
                      <v-icon v-else size="20" color="icono" v-text="'mdi-calendar-check-outline'" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text" />
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'BtnFilter',
  props:{
    value: {
      type: Array,
      default: () => ([])
    },
  },
  data () {
    return {
      selected_sort: this.value,
      options: [
        {
          text: 'Lunes',
          value: '1',
        },
        {
          text: 'Martes',
          value: '2',
        },
        {
          text: 'Míercoles',
          value: '3',
        },
        {
          text: 'Jueves',
          value: '4',
        },
        {
          text: 'Viernes',
          value: '5',
        },
        {
          text: 'Sábado',
          value: '6',
        },
      ]
    }
  },
  computed:{
    selected () {
      return this.options.filter(i => i.value === this.selected_sort)
    },
  },
  watch:{
    value (val, oldVal) {
      if(val === oldVal) return

      this.selected_sort = val
    },
    selected_sort (val, oldVal) {
      if(val === oldVal) return

      this.$emit('input', val)
      this.$emit('update')
    }
  }
}
</script>
